import { formatEther } from '../utils/unit/formatEther.js';
import { formatGwei } from '../utils/unit/formatGwei.js';
import { BaseError } from './base.js';
export function prettyPrint(args) {
  const entries = Object.entries(args).map(([key, value]) => {
    if (value === undefined || value === false) return null;
    return [key, value];
  }).filter(Boolean);
  const maxLength = entries.reduce((acc, [key]) => Math.max(acc, key.length), 0);
  return entries.map(([key, value]) => `  ${`${key}:`.padEnd(maxLength + 1)}  ${value}`).join('\n');
}
export class FeeConflictError extends BaseError {
  constructor() {
    super(['Cannot specify both a `gasPrice` and a `maxFeePerGas`/`maxPriorityFeePerGas`.', 'Use `maxFeePerGas`/`maxPriorityFeePerGas` for EIP-1559 compatible networks, and `gasPrice` for others.'].join('\n'));
    Object.defineProperty(this, "name", {
      enumerable: true,
      configurable: true,
      writable: true,
      value: 'FeeConflictError'
    });
  }
}
export class InvalidLegacyVError extends BaseError {
  constructor({
    v
  }) {
    super(`Invalid \`v\` value "${v}". Expected 27 or 28.`);
    Object.defineProperty(this, "name", {
      enumerable: true,
      configurable: true,
      writable: true,
      value: 'InvalidLegacyVError'
    });
  }
}
export class InvalidSerializableTransactionError extends BaseError {
  constructor({
    transaction
  }) {
    super('Cannot infer a transaction type from provided transaction.', {
      metaMessages: ['Provided Transaction:', '{', prettyPrint(transaction), '}', '', 'To infer the type, either provide:', '- a `type` to the Transaction, or', '- an EIP-1559 Transaction with `maxFeePerGas`, or', '- an EIP-2930 Transaction with `gasPrice` & `accessList`, or', '- a Legacy Transaction with `gasPrice`']
    });
    Object.defineProperty(this, "name", {
      enumerable: true,
      configurable: true,
      writable: true,
      value: 'InvalidSerializableTransactionError'
    });
  }
}
export class InvalidSerializedTransactionTypeError extends BaseError {
  constructor({
    serializedType
  }) {
    super(`Serialized transaction type "${serializedType}" is invalid.`);
    Object.defineProperty(this, "name", {
      enumerable: true,
      configurable: true,
      writable: true,
      value: 'InvalidSerializedTransactionType'
    });
    Object.defineProperty(this, "serializedType", {
      enumerable: true,
      configurable: true,
      writable: true,
      value: void 0
    });
    this.serializedType = serializedType;
  }
}
export class InvalidSerializedTransactionError extends BaseError {
  constructor({
    attributes,
    serializedTransaction,
    type
  }) {
    const missing = Object.entries(attributes).map(([key, value]) => typeof value === 'undefined' ? key : undefined).filter(Boolean);
    super(`Invalid serialized transaction of type "${type}" was provided.`, {
      metaMessages: [`Serialized Transaction: "${serializedTransaction}"`, missing.length > 0 ? `Missing Attributes: ${missing.join(', ')}` : ''].filter(Boolean)
    });
    Object.defineProperty(this, "name", {
      enumerable: true,
      configurable: true,
      writable: true,
      value: 'InvalidSerializedTransactionError'
    });
    Object.defineProperty(this, "serializedTransaction", {
      enumerable: true,
      configurable: true,
      writable: true,
      value: void 0
    });
    Object.defineProperty(this, "type", {
      enumerable: true,
      configurable: true,
      writable: true,
      value: void 0
    });
    this.serializedTransaction = serializedTransaction;
    this.type = type;
  }
}
export class InvalidStorageKeySizeError extends BaseError {
  constructor({
    storageKey
  }) {
    super(`Size for storage key "${storageKey}" is invalid. Expected 32 bytes. Got ${Math.floor((storageKey.length - 2) / 2)} bytes.`);
    Object.defineProperty(this, "name", {
      enumerable: true,
      configurable: true,
      writable: true,
      value: 'InvalidStorageKeySizeError'
    });
  }
}
export class TransactionExecutionError extends BaseError {
  constructor(cause, {
    account,
    docsPath,
    chain,
    data,
    gas,
    gasPrice,
    maxFeePerGas,
    maxPriorityFeePerGas,
    nonce,
    to,
    value
  }) {
    const prettyArgs = prettyPrint({
      chain: chain && `${chain?.name} (id: ${chain?.id})`,
      from: account?.address,
      to,
      value: typeof value !== 'undefined' && `${formatEther(value)} ${chain?.nativeCurrency?.symbol || 'ETH'}`,
      data,
      gas,
      gasPrice: typeof gasPrice !== 'undefined' && `${formatGwei(gasPrice)} gwei`,
      maxFeePerGas: typeof maxFeePerGas !== 'undefined' && `${formatGwei(maxFeePerGas)} gwei`,
      maxPriorityFeePerGas: typeof maxPriorityFeePerGas !== 'undefined' && `${formatGwei(maxPriorityFeePerGas)} gwei`,
      nonce
    });
    super(cause.shortMessage, {
      cause,
      docsPath,
      metaMessages: [...(cause.metaMessages ? [...cause.metaMessages, ' '] : []), 'Request Arguments:', prettyArgs].filter(Boolean)
    });
    Object.defineProperty(this, "cause", {
      enumerable: true,
      configurable: true,
      writable: true,
      value: void 0
    });
    Object.defineProperty(this, "name", {
      enumerable: true,
      configurable: true,
      writable: true,
      value: 'TransactionExecutionError'
    });
    this.cause = cause;
  }
}
export class TransactionNotFoundError extends BaseError {
  constructor({
    blockHash,
    blockNumber,
    blockTag,
    hash,
    index
  }) {
    let identifier = 'Transaction';
    if (blockTag && index !== undefined) identifier = `Transaction at block time "${blockTag}" at index "${index}"`;
    if (blockHash && index !== undefined) identifier = `Transaction at block hash "${blockHash}" at index "${index}"`;
    if (blockNumber && index !== undefined) identifier = `Transaction at block number "${blockNumber}" at index "${index}"`;
    if (hash) identifier = `Transaction with hash "${hash}"`;
    super(`${identifier} could not be found.`);
    Object.defineProperty(this, "name", {
      enumerable: true,
      configurable: true,
      writable: true,
      value: 'TransactionNotFoundError'
    });
  }
}
export class TransactionReceiptNotFoundError extends BaseError {
  constructor({
    hash
  }) {
    super(`Transaction receipt with hash "${hash}" could not be found. The Transaction may not be processed on a block yet.`);
    Object.defineProperty(this, "name", {
      enumerable: true,
      configurable: true,
      writable: true,
      value: 'TransactionReceiptNotFoundError'
    });
  }
}
export class WaitForTransactionReceiptTimeoutError extends BaseError {
  constructor({
    hash
  }) {
    super(`Timed out while waiting for transaction with hash "${hash}" to be confirmed.`);
    Object.defineProperty(this, "name", {
      enumerable: true,
      configurable: true,
      writable: true,
      value: 'WaitForTransactionReceiptTimeoutError'
    });
  }
}
